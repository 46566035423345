*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --vh: 1vh;
}

@supports (height: 1dvh) {
  html {
    --vh: 1dvh !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'NokiaPureText-Regular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block-scroll {
  overflow: hidden;
}

#atcb-bgoverlay {
  backdrop-filter: unset !important;
  background-color: unset !important;
}

.rec-slider-container {
  margin: 0 !important;
}

.rec-dot {
  background-color: #cccccc !important;
  box-shadow: none !important;
  width: 8px !important;
  height: 8px !important;
}

.rec-dot_active {
  background-color: #005aff !important;
}

.rec-pagination {
  margin-top: 5px !important;
}

.rec-item-wrapper {
  height: 100%;
}

.no-outer-margins
  > .rec-carousel-wrapper
  > .rec-carousel
  > .rec-slider-container
  > .rec-slider
  > .rec-swipable
  > .rec-carousel-item:first-child
  > div {
  padding-left: 0px !important;
}

.no-outer-margins
  > .rec-carousel-wrapper
  > .rec-carousel
  > .rec-slider-container
  > .rec-slider
  > .rec-swipable
  > .rec-carousel-item:last-child
  > div {
  padding-right: 0px !important;
}

.toast-class {
  max-width: 75%;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

form[id^='mktoForm_'] {
  font-family: NokiaPureText-Regular !important;
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.mktoForm input,
textarea {
  font-size: 16px !important;
  background: #ebebeb !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 21px 10px !important;
  outline: none !important;
  box-shadow: unset !important;
}

.mktoForm textarea {
  min-height: 120px;
}

.mktoForm input:focus,
textarea:focus,
select:focus {
  border: 1px solid #005aff;
}

.mktoForm select {
  font-size: 16px !important;
  background: #ebebeb !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  padding: 9px 10px !important;
  outline: none !important;
  box-shadow: unset !important;
}

.mktoForm button[type='submit'] {
  position: relative !important;
  height: unset !important;
  background-color: #005aff !important;
  background-image: unset !important;
  border: none !important;
  padding: 10px 30px !important;
  font-size: 20px !important;
  font-family: NokiaPureHeadline-Regular !important;
  color: #fff !important;
  border-radius: 4px !important;
  width: 100%;
  max-width: 450px;
}

.mktoFormRow {
  width: 100%;
}

.mktoButtonRow {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mktoButtonRow > span {
  margin: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

#LblmktoOptin > a {
  padding: 0 !important;
}

#LblEmail {
  display: none !important;
}

.mktoForm input[type='checkbox'] + label:before {
  background: #ebebeb !important;
  box-shadow: unset !important;
}

.mktoForm .mktoCheckboxList > label > a {
  padding: 0 !important;
}

/* ERRORS */

.mktoError {
  position: unset !important;
}

.mktoError > .mktoErrorArrowWrap {
  display: none !important;
}

.mktoErrorMsg {
  display: flex !important;
  flex-direction: column !important;
  background: unset !important;
  color: #e23b3b !important;
  border: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}
